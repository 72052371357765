.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*
 * Cool Maze legacy below
 */

body {
  text-align: center;
}
input[readonly]
{
  background-color: white;
}
#qr-zone {
  /*clear: both;*/
}


@keyframes kf-resize-1-to-2 {
  0% {
    width: 125px;
    height: 125px;
  }
  80% {
    width: 300px;
    height: 300px;
  }
  100% {
    width: 250px;
    height: 250px;
  }
}


@keyframes kf-resize-2-to-4 {
  0% {
    width: 250px;
    height: 250px;
  }
  80% {
    width: 550px;
    height: 550px;
  }
  100% {
    width: 500px;
    height: 500px;
  }
}


@keyframes kf-resize-4-to-6 {
  0% {
    width: 500px;
    height: 500px;
  }
  80% {
    width: 800px;
    height: 800px;
  }
  100% {
    width: 750px;
    height: 750px;
  }
}


@keyframes kf-resize-6-to-2 {
  0% {
    width: 750px;
    height: 750px;
  }
  80% {
    width: 220px;
    height: 220px;
  }
  100% {
    width: 250px;
    height: 250px;
  }
}

@keyframes kf-resize-1-to-4 {
  0% {
    width: 125px;
    height: 125px;
  }
  80% {
    width: 550px;
    height: 550px;
  }
  100% {
    width: 500px;
    height: 500px;
  }
}

@keyframes kf-resize-1-to-6 {
  0% {
    width: 125px;
    height: 125px;
  }
  80% {
    width: 800px;
    height: 800px;
  }
  100% {
    width: 750px;
    height: 750px;
  }
}

/* Ensmallen from keyboard shortcut */
@keyframes kf-resize-4-to-2 {
  0% {
    width: 500px;
    height: 500px;
  }
  80% {
    width: 220px;
    height: 220px;
  }
  100% {
    width: 250px;
    height: 250px;
  }
}

/* Ensmallen from keyboard shortcut */
@keyframes kf-resize-6-to-4 {
  0% {
    width: 750px;
    height: 750px;
  }
  80% {
    width: 450px;
    height: 450px;
  }
  100% {
    width: 500px;
    height: 500px;
  }
}

#qrcode {
  border: 0.5rem solid white;
  border-radius: 1rem;
  background-color: white;
  text-align: center;
}

#qrcode.qr-resize-1-to-2 > canvas {
  animation: kf-resize-1-to-2 0.2s ease-in;
}
#qrcode.qr-resize-2-to-4 > canvas {
  animation: kf-resize-2-to-4 0.2s ease-in;
}
#qrcode.qr-resize-4-to-6 > canvas {
  animation: kf-resize-4-to-6 0.2s ease-in;
}
#qrcode.qr-resize-6-to-2 > canvas {
  animation: kf-resize-6-to-2 0.2s ease-in;
}
#qrcode.qr-resize-1-to-4 > canvas {
  animation: kf-resize-1-to-4 0.2s ease-in;
}
#qrcode.qr-resize-1-to-6 > canvas {
  animation: kf-resize-1-to-6 0.2s ease-in;
}
#qrcode.qr-resize-4-to-2 > canvas {
  animation: kf-resize-4-to-2 0.2s ease-in;
}
#qrcode.qr-resize-6-to-4 > canvas {
  animation: kf-resize-6-to-4 0.2s ease-in;
}

#qrcode:hover {
  animation: shake 0.2s;
  animation-iteration-count: 2;
}
@keyframes shake {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(-1deg) scale(0.96);
  }
  75% {
    transform: rotate(2deg) scale(1.04);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}


#qrcode img {
  /*float: left;*/
  margin: auto;
  /*margin-top: 20px;*/
  border-radius: 12px;
  cursor: zoom-in;
}
#overprint-logo {
  position: absolute;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* Mitigating bug #146 */
  #qrcode img.overprint {
    margin-top: -800px;
  }
}
#progress-zone {
  min-height: 34px;
  padding: 8px;
}

.please-reload button {
  background-color: white;
  padding-top: 2px;
  margin-bottom: 6px;
  border-width: 3px;
  cursor: pointer;
}

.please-reload button:hover {
  border: 3px black solid;
  border-radius: 6px;
}

/*
#txt-msg-zone {
  height: 100%;
  margin-left: 10%;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  text-align: center;
  border-radius: 15px;
}
*/
#txt-msg-close {
  float: right;
  margin-top: -0.5em;
  font-weight: bolder;
  font-size: 2em;
  cursor: pointer;
}

#help {
  max-width: 54em;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  background-color: white;
  border: 8px solid black;
  padding: 8px;
  text-align: left;
  border-radius: 15px;
}
#help-contents {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0.6em;
}
#help-contents .dim {
  color: #777;
}
#help-contents .dim a:link{
  color: #66A;
}
#help-contents .dim a:visited{
  color: #70538b;
}
#help-close {
  flex: 100%;
  text-align: right;
  margin-top: -0.6em;
  margin-right: -0.3em;
  font-weight: bolder;
  font-size: 2em;
  cursor: pointer;
}
#help ol.steps {
  flex: 20em;
  max-width: 30em;
}
#help .steps li {
  background-color: #DED;
  padding: 0.5em;
  padding-right: 1em;
  margin-bottom: 1em;
  border-radius: 1em;
  border-bottom-right-radius: 3em;
  font-size: 1.2em;
  color: #353;
  font-weight: bolder;
}
.stores {
  margin-top: 6px;
}
.stores img {
  width: 160px;
  margin-right: 12px;
}
.stores .verysoon {
  border: 5px black dashed;
  border-radius: 6px;
}
#help .warning-specific-QR {
  flex: 100%;
  margin: 0;
  margin-left: 2em;
  color: #D77;
  font-weight: bold;
}
#help .intro {
  flex: 100%;
}
.tutorial-video {
  flex: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}
.tutorial-video iframe {
  width: 32em;
  height: 24em;
  max-width: 100%;
}
#help p {
  flex: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.2em;
}

.link-to-dual {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  border-top: dotted #777;
  padding-top: 2px;
}
.mini-picto {
  width: 1em; 
  vertical-align:middle; 
  padding-bottom:2px;
}
.rounded {
  border: 1px solid #AAA;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
  white-space: nowrap;
}
.ib {
  display: inline-block;
}
.thumb {
   border: 4px solid transparent;
   margin: 6px;
}
.thumb.link {
  border: 4px solid yellow;
  border-radius: 2px;
  cursor: pointer;
}

/*
 * Cool Maze for React below
 */

html,
body,
#root,
.App {
  height: 100%;
  min-height: 100%;
}

header {
  /*
  height: 7%;
  */
  background-color: black;
  color: grey;
  box-sizing: border-box;
  min-height: 52px;
}

table.topbar {
  width: 100%;
}

.topbar td {
  width: 20%;
  vertical-align: top;
}

.topbar img.spinning {
  margin-top: 6px;
  height: 36px;
}

.topbar td {
  min-width: 60px;
}

.topbar button {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.topbar button:hover {
  border: 2px white solid;
  border-radius: 6px;
  width: 52px;
}

.topbar button > svg {
  width: 36px;
  height: 36px;
  color: white;
}

.topbar .error {
  margin-top: 4px;
  width: 36px;
  height: 36px;
  /*color: #FF5555;*/
  color: red;
}

.topbar button.pressed {
  border-style: inset;
  border-radius: 6px;
  width: 52px;
}

.error-box {
  margin-top: 6px;
  padding: 0.2em;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: larger;
  text-shadow: 1px 1px black;
  text-align: center;
  border-radius: 5px;
}

.main {
  height: 93%;
  box-sizing: border-box;
  padding-top: 8px;
}

#help {
  margin-top: 16px;
}

img.help-schema {
  padding-top: 1em;
  flex: 6em;
  display: block;
  max-width: min(20em, 75%);
  height: auto;
}

#qr-zone {
  padding-top: 16px;
}

#qrcode {
  display: inline-block;
}

#qrcode canvas {
  border-radius: 12px;
  cursor: zoom-in;
  max-width: 90vw;
  max-height: 90vw;
}

#inbox {
  height: 90%;
  min-height: 50px;
  width: 94%;
  margin-left: 2%;
  box-sizing: border-box;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding: 32px;
  /*
  font-size: 3em;
  */
}

.resource-receiving {
  border: dotted 8px;
  cursor: progress;
  padding: 5px;
}

.resource-thumb {
  /* border: solid 4px black;  border not possible with fit-up, for now */
  cursor: progress;
  -webkit-filter: blur(3px);
  filter: blur(3px) contrast(110%);
}

.very-blurry{
  filter: blur(30px);
  -webkit-filter: blur(30px);
}

.resource-picture {
  border: 8px solid black;
  border-radius: 15px;
  cursor: zoom-in;
}

.resource-video {
  border: 8px solid black;
  border-radius: 15px;
}

.resource-audio {
  border: 8px solid black;
  border-radius: 15px;
  padding: 1rem;
}

.fit-down {
  max-width: 100%;
  max-height: 100%;
}

.fit-up {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.fit-up-height {
  display: block;
  height: 100%;
  width: auto;
}

.fit-up-width {
  display: block;
  min-width: 100%;
  height: auto;
}

.download-item {
  border: none;
  background-color: transparent;
  color: #777;
  cursor: pointer;
}

.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw  !important;
  height: 100vh !important;
  margin: 0 !important;

  background: linear-gradient(#558C, #005C);

  display: flex;
  align-items: center;
  align-items: center;

  transition: opacity ease 0.4s;

  visibility: visible;
  opacity: 1;

  width: 100vw;
  height: 100vh;
  object-fit: contain;

  border: none;
  border-radius: 0;
  cursor: zoom-out;
}


.inbox-text {
  margin-top: 16px;
  height: 90%;
  margin-left: 10%;
  width: 80%;
  padding: 12px;
  background-color: white;
  color: #222;
  text-align: center;
  border: 8px solid black;
  border-radius: 15px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.inbox-text h3 {
  margin-top: 4px;
  margin-bottom: 8px;
}

.inbox-file {
  text-align: center;
  height: 90%;
  min-height: 50px;
  width: 94%;
  margin-left: 2%;
  padding-left: 20px;
  padding-right: 20px;
  padding: 32px;
}

.file-item {
  margin: auto;
  width: 300px;
  min-height: 100px;
  border: 8px solid black;
  border-radius: 15px;
  padding: 10px;
  background-color: white;
}

.file-item button {
  background: white;
  border: none;
}

.youtube-item .external-link{
  display: block;
  margin-bottom: 0.4em;
}

.selected-color-invert {
  display: inline-block;
  color: white;
  background-color: black;
  padding-top: 0;
  padding-bottom: .1rem;
  padding-left: 1rem;
  padding-right: 0rem;
  margin: 0.2rem;
}

.big-caret {
  font-size: 1.1rem;
  font-weight: normal;
}

#copy-to-clipboard {
  border: none;
  background: none;
  cursor: pointer;
}

#copy-to-clipboard svg {
  width: 5em;
  height: 3em;
}

#copy-to-clipboard .checked {
  margin-left: -0.4em;
  /* visibility: hidden; */
  font-weight: bold;
  color: #050;
  font-size: 2.5em;
}

#text-message {
  height: 90%;
  min-height: 50px;
  width: 94%;
  margin-left: 2%;
  box-sizing: border-box;
  border: none;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;

  white-space: pre-line;
  font-size: 3em;
  font-family: "Arial Black", Gadget, sans-serif;
}

.inbox-external-url h3 {
  margin-top: 4px;
  margin-bottom: 8px;
}

.inbox-external-url {
  width: 90%;
  min-height: 50px;
  margin-top: 16px;
  margin-left: 4%;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 32px;
  background-color: white;
  text-align: center;
  border: 8px solid black;
  border-radius: 15px;
  box-sizing: border-box;
}

.external-webpage {
  font-size: 2em;
  word-break: break-all;
}

.hidden {
  visibility: hidden;
}

button:disabled{
  visibility: hidden;
}

.resource-download-progress {
  margin-bottom: 1em;
}

.resource-download-progress p {
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

.resource-download-progress progress {
  width: 14em;
}


#inbox-multi {
  height: 90%;
  min-height: 50px;
  width: 94%;
  padding-top: 16px;
  margin-left: 2%;
}

.multi-item {
  float: left;
  margin-left: 30px;
  margin-top: 12px;
  width: 300px;
  min-height: 100px;
}

.multi-item img {
  width: 100%;
  border: 4px solid black;
  border-radius: 8px;
}

.multi-item img.thumb {
  /* border: dotted 4px; */
  cursor: progress;
  -webkit-filter: blur(3px);
  filter: blur(3px) contrast(110%);
}

.multi-item img.resource {
  cursor: pointer;
  min-height: 100px;
}

.downscaled svg,
.e2ee-message svg {
  margin-top: -4px;
  padding-right: 4px;
  height: 1.5em;
  width: 1.5em;
}

.item-extra-info {
  margin: 0.5em;
  font-size: 0.8em;
  color: #777;
}

.e2ee-message .hint {
  text-decoration: none;
  border-bottom-style: dotted;
  border-bottom-width: 2px;
  border-bottom-color: #BBB;
}

.zip-progress progress {
  margin-left: 0.8rem;
  margin-right: 0.4rem;
  background-color: white;
}

progress::-moz-progress-bar { 
  background-color: blue;
}
progress.complete::-moz-progress-bar { 
  background-color: green;
}
progress::-webkit-progress-value {
  background-color: blue;
}
progress.complete::-webkit-progress-value {
  background-color: green;
}
progress {
  color: blue;
}
progress.complete {
  color: green;
}

.ios-only, .android-only {
  display: none;
} 

@media (max-width: 5in) {
  /* Narrow screen (phones, portrait mode) */

  @supports (-webkit-overflow-scrolling: touch) {
    .ios-only {
      display: block;
    } 
  }

  @supports not (-webkit-overflow-scrolling: touch) {
    /* CSS for other than iOS devices */ 

    .android-only  {
      display: block;
    }
  }


  #help img.help-schema {
    padding-left: 8%;
    padding-right: 8%;
    width: 84%;
    max-width: 84%;
  }


  #help .warning-specific-QR {
    margin-left: 0%;
  }
}

.mobile-app-banner-promo {
  padding: 2rem 1rem 2rem 1rem;
}

.mobile-app-banner-promo .product{
  padding-bottom: 1rem;
}

.mobile-app-banner-promo img {
  width: 100%;
}

/* Fullscreen mode: display a big promotional URL bar.
   Disabled: see bigurl.js.
.browserbar {
    display: none;
    background-color: #cecece;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
}
@media (max-device-height: 102vh) {
  .browserbar {
      display: block;
  }
}
.urlbar {
    border: 2px solid #8c8c8c;
    border-radius: 12px;
    background-color: white;
    margin-left: 4rem;
    margin-right: 2rem;
    padding-left: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 3rem;
    text-align: left;
}
.urlbar span{
    padding: 0;
}
.urlbar .lock {
  padding: 0;
  display: inline-block;
  font-size: 2.5rem;
}
.urlbar .pipe {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}
.urlbar .green {
    color: #070;
}
.urlbar .gray {
    color: #AAA;
}
.urlbar .star {
    position: absolute;
    right: 3rem;
}
 */

/* Fullscreen mode: display a big promotional domain name. */
.promodomain {
  display: none;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  font-weight: bolder;
  color: #BBB;
  font-size: 7rem;
  font-family: "Bookman";
}
@media (max-device-height: 102vh) {
  .promodomain {
      display: block;
  }
}

@media (prefers-color-scheme: dark) {
  /* Dark theme: see issues/427 */
  body {
	  background-color: #003;
	  color: #DDD;
  }
  a:link {
    color: #AFF;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    color: #EEF;
    text-decoration: none;
  }
  a:visited {
    color: #CCF;
    text-decoration: none;
  }
  #help {
	  background-color: #025;
	  color: #EEE;
    border: 3px solid #ddd;
    border-radius: 5px;
  }
  #help .steps li {
    background-color: #147;
    color: #EEE;
  }
  #help-contents .dim {
    color: #AAC;
  }

  #help-contents .dim a:link{
    color: #B99;
  }
  #help-contents .dim a:visited{
    color: #A88;
  }

  #help .warning-specific-QR {
    color: #F70;
  }
  .e2ee-message .hint {
    border-bottom-color: #444;
  }

  /* Thin white border */
  .resource-thumb {
    /* border: 3px solid #DDD; border not possible with fit-up, for now */
  }
  .resource-picture,
  .resource-video,
  .multi-item img {
    border: 3px solid #DDD;
    border-radius: 5px;
  }

  /* White box, even in dark mode.*/
  .inbox-text a {
    color: #44D;
  }

  .inbox-text a:visited{
    color: #66E;
  }
}